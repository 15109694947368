.contact {
  padding: 10px;
  text-align: center;
}

.contact .row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.contact .row .image {
  flex: 1;
  text-align: left;
  padding: 0 2rem;
}

.contact .row .image img {
  height: 500px;
  width: 500px;
  max-width: 100%;
}

.contact .row .form-container {
  flex: 1;
  text-align: left;
  padding: 0 1rem;
  max-width: 600px;
  margin: 0 auto;
}

.contact .row .form-container h2 {
  text-align: right;
}

.contact .row .form-container input,
.contact .row .form-container textarea {
  outline: none;
  border: none;
  height: 3rem;
  background: none;
  border-radius: .5rem;
  box-shadow: .2rem .2rem .5rem rgba(0, 0, 0, .2);
  padding: 0 1rem;
  margin: 1rem 0;
  font-size: 1rem;
  width: 100%;
 
  
}

.contact .row .form-container .inputBox {
  display: flex;
  justify-content: space-between;
}

.contact .row .form-container .inputBox input[type="text"] {
  width: 48%;
}

.contact .row .form-container input[type="email"] {
  width: 100%;
}

.contact .row .form-container textarea {
  width: 100%;
  height: 7rem;
  padding: 0.5rem;
  resize: none;
}

.contact .row .form-container input[type="submit"] {
  background-color: #2085ded8;
  color: #000;
  cursor: pointer;
  height: 3rem;
  width: 100%;
}

.contact .row .form-container input[type="submit"]:hover {
  opacity: .8;
}

@media screen and (max-width: 768px) {
  .contact .row {
    flex-direction: column;
  }

  .contact .row .image {
    flex: 1;
    text-align: center;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact .row .image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  

  .contact .row .form-container {
    padding: 0 3rem;
    margin: 5px ;
  }

  .contact .row .form-container textarea {
    height: 5rem;
  }
}
