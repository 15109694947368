.carousel-item {
  height: 90vh; /* Update the height to 90% of the viewport height */
  min-height: 300px;
  width: 100%;
  top: -2;
  position: relative;
  background-color: transparent;
  }

  .fireworks-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.8;
  }
  

.carousel-caption {
  top: 10%;
  bottom: unset;
  z-index: 1;
 
}
 /* .carousel-caption h3{
  display: inline-block;
  background: linear-gradient(red,yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.carousel-caption h3 {
  font-size: 40px;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-top: 25px;
  margin-bottom: 20px;
  color: #fff;
font-weight: 700;
  font-family: 'Inter', sans-serif;
  
}

.carousel-caption p {
  width: 60%;
  margin: auto;
  font-size: 18px;
  line-height: 1.9;
}

.carousel-inner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .carousel-caption {
    bottom: 350px;
  }
  .carousel-caption p {
    width: 100%;
  }
  .carousel-inner::before {
    background: rgba(0, 0, 0, 0.4);
  }
  .carousel-item  {
    height: 50vh;
  }
  .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 60%;
    height: 50vh;
  }
}

@media only screen and (max-width: 767px) {
  .carousel-caption {
    bottom: 250px;
  }
  .carousel-item  {
    height: 50vh;
  }
  .carousel-item > img {
    position: absolute;
    top: -2;
    left: 0;
    min-width: 60%;
    height: 50vh;
  }
  .carousel-caption h3 {
    font-size: 30px;
  }
  .carousel-caption p {
    font-size: 16px;
  }
  .carousel-inner::before {
    background: rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (max-width: 575px) {
  .carousel-caption {
    bottom: 150px;
  }
  .carousel-item,
  .carousel-item > img {
    height: 50vh;
  }
  .carousel-caption h3 {
    font-size: 25px;
  }
  .carousel-caption p {
    font-size: 14px;
    line-height: 1.6;
  }
  .carousel-inner::before {
    background: rgba(0, 0, 0, 0.5);
  }
}
