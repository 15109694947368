/* Default styles */
.container {
  height: auto;
  text-align: center;
}

h1 {
  color:rgb(18, 18, 119);
  font-size: 50px;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.h{
  width: 4%;
  height: 3%;

  border-radius: 5px;
  margin-bottom: 10px;
}

.word {
  color: brown;
  font-size: 70px;
  font-weight: bold;
  text-shadow: 2px 2px #ddd;
}

.card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  width: 50%;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.card-block {
  margin-bottom: 20px;
}

.card-block h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.card-block p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  .word {
    font-size: 50px;
  }
  
  .card {
    width: 80%;
  }
  
  .card-block h4 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  
  .card-block p {
    font-size: 14px;
    line-height: 1.3;
  }
}

/* Media queries for extra small screens */
@media (max-width: 540px) {
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .word {
    font-size: 40px;
  }
  
  .card {
    width: 90%;
    padding: 10px;
    margin: 10px auto;
  }
  
  .card-block h4 {
    font-size: 18px;
    margin-bottom: 6px;
  }
  
  .card-block p {
    font-size: 12px;
    line-height: 1.2;
  }
}
