.carousel-slider-wrapper {

  
  margin: 0 auto;
}

 
.carousel-slider-wrapper .carousel-slider {
  position: relative;
  overflow: hidden;
}

.carousel-slider-wrapper .carousel-slider .slider-wrapper {
  display: flex;
  flex-direction: row;
  transition: all 0.5s ease;
}

.carousel-slider-wrapper .carousel-slider .slider-wrapper .item-wrapper {
  flex: 1;
  margin-right: 20px;
  position: relative;
}

.carousel-slider-wrapper .carousel-slider .slider-wrapper .item-wrapper:last-child {
  margin-right: 0;
}

.carousel-slider-wrapper .carousel-slider .slider-wrapper .item-wrapper .slide-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slider-wrapper .carousel-slider .slider-wrapper .item-wrapper .slide-item .slider-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
}


.carousel-slider-wrapper .carousel-slider .slider-wrapper .item-wrapper .slide-item .slider-content h3 {
  margin: 0;
  font-size: 24px;
}

.carousel-slider-wrapper .carousel-slider {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.carousel-slider-wrapper .carousel-slider  {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-slider-wrapper .carousel-slider  {
  background-color: #fff;
}
.carousel-container {
  position: relative;
  width: 100%;
  }
  
  .modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;

  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 545px;
  max-height: 600px;

  }
  
  .close-btn {
    
    border-radius: 150px;
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
  font-size: 70px;
  }