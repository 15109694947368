#navbar-nav a {
  font-size: 18px;
  font-weight: 500;
  color: black; /* Set the default color to black */
}


#navbar-nav a:hover {
  color: red; /* Change the hover color to red (you can use any color you prefer) */
}

.navbar-logo {
  max-width: 100px; /* Adjust the width as needed */
  margin-right: 20px; /* Add some right margin to separate it from other elements */
}

.log-1 {
  width: 70%;
  height: 60%;
  object-fit: cover;
  justify-content: start;
}
#navbar-light .navbar-brand {
  color: black;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}

#navbar-light .navbar-brand:focus,
#navbar-light .navbar-brand:hover {
  color: #000;
}

#navbar-light .navbar-brand .navbar-link {
  color: #000;
  border-top: 4px solid #000000;
  border-bottom: 4px solid #000000;
  padding: 6px 0px;
}


  @media only screen and (max-width: 991px) {
    #navbar-nav a {
    font-size: 14px;
    font-weight: 500;
   
    }
    .navbar-light .navbar-brand {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    }
    .navbar-light .navbar-brand .navbar-link {
    border-top: none;
    border-bottom: none;
    padding: 0px;
    }
    }

    @media only screen and (max-width: 767px) {
      .navbar-toggler {
      display: block;
      }
      .navbar-collapse {
      display: none;
      }
      .navbar-nav {
      flex-direction: column;
      margin-top: 15px;
      }
      .navbar-nav a {
      font-size: 16px;
      font-weight: 500;
      
      margin-bottom: 10px;
      }
      .navbar-light .navbar-brand {
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 10px;
      }
      .navbar-light .navbar-brand .navbar-link {
      border-top: none;
      border-bottom: none;
      padding: 0px;
      }
      .navbar-collapse.show {
      display: block;
      }
      }
      @media only screen and (max-width: 570px) {
        .navbar-toggler {
        margin-top: 15px;
        }
        .navbar-collapse {
        margin-top: 15px;
        }
        .navbar-nav a {
        font-size: 14px;
        margin-bottom: 5px;
        }
        .navbar-light .navbar-brand {
        font-size: 16px;
        letter-spacing: 0.5px;
        margin-top: 10px;
        }
        }
