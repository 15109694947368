.advisory-committee {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
}

.advisory-committee h2 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #d84f4f;
  
}

.advisory-committee ul {
  list-style-type: none;
  padding: 0;
}

.advisory-committee li {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.advisory-committee li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.advisory-committee strong {
  color: #333;
}

.advisory-committee .position {
  font-style: italic;
  color: #666;
}









.team{
  background-color: #ffffff;

}
.image{
  border-radius: 20px;
}
.teamcard {
  
  width: 240px;
  margin: 50px;
  justify-self: center;
  text-align: center;
  
}
.cardgrid, .jcc {
  justify-content: center;
  align-items: center;
}


.teamcard .image {
  display: flex;
  justify-content: center;
}

.teamcard img {
  max-width: 100%;
  height: 230px;
  object-fit: cover;
  border-radius: 18px;
  animation-duration: 1s;
  opacity: 1;
}

.teamcard .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}
.m{

  margin-left: 150px;
}
.teamcard .u {
  color: #000000;
  font-size: 24px;
  margin-bottom: 10px;
 
  font-weight: bold;
  font-family: "poppins", sans-serif;
}

.teamcard .r {
  color: #000;
  font-size: 18px;
  font-family: "poppins", sans-serif;
}

.team {
  
  padding: 50px 0;
 
}

.headings {
  display: flex;
  justify-content: center;
  align-items: center;
  color: brown;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: "poppins", sans-serif;
}
@media (max-width: 768px) {
  .m {
    text-align: center; /* Center align the content */
    margin: 70px; /* Remove the left margin */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

