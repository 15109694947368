.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff6347; /* Tomato Red */
  opacity: 0.8;
  animation: fade-in-out 1.5s infinite;
}

.circle:nth-child(2) {
  animation-delay: 0.3s;
  background-color: #3498db; /* Blue */
}

.circle:nth-child(3) {
  animation-delay: 0.6s;
  background-color: #27ae60; /* Green */
}
/* it was updated */
@keyframes fade-in-out {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}
