
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 

}

h5{
  font-size: 30px;
  font-weight: 600;

  font-family: 'Inter', sans-serif;
 
}
p{
  font-family: 'Inter', sans-serif;
}
.left-card {
  margin-top: 30px;
}

.right-card {
  margin-bottom: 30px;
}

.card-img-top {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.card-body {
  
  padding: 20px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 14px;
  line-height: 1.5;
}

.list-group-item {
  font-size: 14px;
  padding: 8px 16px;
  border: none;
}

.card-footer {
  background-color: #f8f9fa;
  padding: 10px;
  text-align: right;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  text-decoration: none;
}

.btn-primary:hover {
  background-color: #0069d9;
}
