/* Style for Events Tab */
.heading 
{
    text-align: center;
    color: rgb(196, 80, 72);
}

.events-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .events-heading {
    text-align: center;
    width: 100%;
  }
  
  .event-card {
    border: 1px solid #ccc;
    padding: 10px;
    width: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .event-card img {
    max-width: 100%;
  }
  
  /* Style for Event Details Modal */
  .event-details-modal {
    position: fixed;
    top: 0;
   
  
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    margin-top: 88px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
 
 /* Style for Event Details */
.event-details {
    text-align: left; 
    margin-top: 10px;
    padding: 0 20px; 
    color: #333; 
    font-family: Arial, sans-serif; 
    font-weight: 400; 
  }
  
  .event-details p {
    margin: 5px 0;
    font-size: 20px; 
    line-height: 1.5; 
  }
  
  .event-details p span {
    color: #ff6600; 
    font-weight: bold; 
  }
  

  .view-more-button {
    background-color: lightblue;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .view-more-button:hover {
    background-color: greenyellow;
  }


/* Existing styles ... */

/* Style for Event Carousel */
.event-carousel {
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
  }
  
  /* Styles for Carousel Arrows */
  .carousel .control-arrow {
    font-size: 20px;
    background: none;
    color: #333;
    border: none;
  }
  
  .carousel .control-arrow:hover {
    color: #007bff;
  }
  
  /* Styles for Carousel Indicators */
  .carousel .carousel-indicators {
    bottom: -25px;
  }
  
  .carousel .carousel-indicators li {
    background-color: #ccc;
    border-color: #ccc;
  }
  
  .carousel .carousel-indicators .selected {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  /* Style for Event Details */
  .event-details {
    text-align: left;
    margin-top: 10px;
    padding: 0 20px;
    color: #333;
    font-family: Arial, sans-serif;
    font-weight: 400;
  }
  
  .event-details p {
    margin: 5px 0;
    font-size: 20px;
    line-height: 1.5;
  }
  
  .event-details p span {
    color: #ff6600;
    font-weight: bold;
  }

  .carousel-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px; 
  }
  
  /* Style for "View More" Button */
  .view-more-button {
    background-color: blue;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .view-more-button:hover {
    background-color: lightblue;
  }
  